/* Quitador Sans Pro */
@font-face {
  font-family: "Quitador Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/quitador-sans-pro/80917868752bd1c18ced0770abfdd39e.eot"); /* IE9*/
  src: url("../assets/fonts/quitador-sans-pro/80917868752bd1c18ced0770abfdd39e.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("../assets/fonts/quitador-sans-pro/80917868752bd1c18ced0770abfdd39e.woff2") format("woff2"), /* chrome firefox */
       url("../assets/fonts/quitador-sans-pro/80917868752bd1c18ced0770abfdd39e.woff") format("woff"), /* chrome firefox */
       url("../assets/fonts/quitador-sans-pro/80917868752bd1c18ced0770abfdd39e.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
       url("../assets/fonts/quitador-sans-pro/80917868752bd1c18ced0770abfdd39e.svg#Quitador Sans Pro") format("svg"); /* iOS 4.1- */
  font-display: swap;
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/roboto/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
       url('../assets/fonts/roboto/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/roboto/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/roboto/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/roboto/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/roboto/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
  font-display: swap;
}

%h1-title {
  font-style: italic;
  font-weight: 700;
  letter-spacing: -2px;
  letter-spacing: -1px;
  line-height: 1;
  font-family: "Quitador Sans Pro", Helvetica, Arial, sans-serif;
  font-display: swap;
}



// Page
// =============================================================================

.page-title {
  @extend %h1-title;
  padding: 0;
  border: 0;
  color: #595758;
}


.page-title {
  margin-top: 0;

  @media (min-width: 427px) and (max-width: 769px) {
    font-size: 2.3em;
    font-style: italic;
    font-weight: 100;
    letter-spacing: 0.3px;
    margin-bottom: 0.2em;    
  }

  @media (min-width: 769px) and (max-width: 1280px) {
    font-size: 3em;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0.3px;
    margin-bottom: 0;  
  }
  @media (min-width: 1281px) {
    font-size: 4em;
    font-style: italic;
    margin-bottom: 0;
    letter-spacing: 0px; 
    color: #646464; 
    padding-bottom: 10px;

    @supports (background-clip: text) or (-webkit-background-clip: text) {
      color: #59575800;
      background: linear-gradient(176deg, #818181 34%, #595758 60%);
      -webkit-background-clip: text;
      background-clip: text;  
    }
    
  }
} 




.page-title__category {
  color: #00a2db;
  display: block;
  font-size: .45em;
  margin-bottom: .2em;
  font-weight: 600;
  letter-spacing: 5px;
  margin-left: .6em;
}
 
.page-header__description {
  @media (min-width: 0px)   and (max-width: 426px) {
    font-size: 1.25em;
    color: #757575;
    line-height: 1.4;
    margin-top: .2em;
  }
  
  @media (min-width: 427px) and (max-width: 768px) {
    font-size: 1.3em;
    color: #757575;
    line-height: 1.4;
    margin-top: .2em;
  }

  @media (min-width: 769px) and (max-width: 1280px) {
    font-size: 1.5em;
    color: #757575;
    letter-spacing: .05em;
    line-height: 1.4;
    margin-top: .5em;
  }

  @media (min-width: 1281px) {
    font-size: 1.7em;
    color: #757575;
    letter-spacing: .05em;
    line-height: 1.4;
    margin-top: .2em;    
  }
}


.layout-frontpage{

    .page-title {
        @extend %h1-title;
        font-size: 1rem;
        text-align: center;
        color: #9de1f9;
        margin-top: .3rem;
        font-weight: 400;
        letter-spacing: 0px;
        
        @include media-query($on-laptop) {        
            font-size: 1.3rem; 
            max-width: none;

        }
    }
}



// Posts
// =============================================================================

.post-title {
  @extend %h1-title;
  margin-top: 0;

  @include media-query($on-laptop) {
    font-size: 36px;
  }
}


.post-container h1{ font-size: 1.5em; }
.post-container h2{ 
  font-size: 1.35em; 
  margin-bottom: 10px;
  display: inline-block;
  
  border-bottom: 1px solid #eaecef;
  width: 100%;
}
.post-container h3{ 
  font-size: 1.215em;
  display: inline-block;
  margin-bottom: 10px;
  line-height: 1;
  width: 100%;
}
.post-container h4{ font-size: 1em; }
.post-container h5{ font-size: 0.875em; }
.post-container h6{ font-size: 0.85em; }

@media (min-width: 427px){
  .post-container h1{ font-size: 2.2em; }
  .post-container h2{ 
    font-size: 1.7em; 
    border-bottom: 1px solid #eaecef;
  }
  .post-container h3{ font-size: 1.45em;}
  .post-container h4{ font-size: 1.2em; }
  .post-container h5{ font-size: 1.075em; }
  .post-container h6{ font-size: 1.05em; }

}

@media (min-width: 1280px){
  .post-container h1{ font-size: 3em; }
}


.post-content p { 
  @media (min-width: 427px) {
    font-size: 1.1em;
  }
  
}

.layout-sidebar p { 
  @media (min-width: 427px) {
    font-size: 1.1em;
  }
  
}



// tile
// =============================================================================

.tile-category__title{
  
}

.tile-category__title--head{
  @media (min-width: 0px)   and (max-width: 426px) {
    font-family: "Roboto";
    color: #595959;
    font-size: .8em;
    margin-bottom: -1px;
    border-bottom: solid 1px #595758;
    font-weight: 600;
    letter-spacing: 0.5px;
    width: max-content;
    text-transform: uppercase;
    padding-bottom: .35em;
  }

  @media (min-width: 427px) and (max-width: 768px) {
    font-family: "Roboto";
    color: #595959;
    font-size: .85em;
    margin-bottom: -1px;
    border-bottom: solid 1px #595758;
    font-weight: 400;
    letter-spacing: 0.5px;
    width: max-content;
    text-transform: uppercase;
    padding-bottom: .65em;    
  }  

  @media (min-width: 769px) and (max-width: 1280px) {
    font-family: "Roboto";
    color: #595959;
    font-size: .9em;
    margin-bottom: -1px;
    border-bottom: solid 1px #595758;
    font-weight: 400;
    letter-spacing: 0.5px;
    width: max-content;
    text-transform: uppercase;
    padding-bottom: .7em;    
  }  

  @media (min-width: 1281px) {
    font-family: "Roboto";
    color: #595959;
    font-size: 1.2em;
    margin-bottom: -1px;
    border-bottom: solid 1px #595758;
    font-weight: 400;
    letter-spacing: 0.5px;
    width: max-content;
    text-transform: uppercase;
    padding-bottom: .35em;    
  }

}

.tile-category__title--desc{
  @media (min-width: 0px) and (max-width: 426px) {
    color: #00a2db;
    display: block;
    font-size: .8em;
    margin-bottom: .2em;
    font-weight: 600;
    letter-spacing: 3px;
  }
  @media (min-width: 427px) {
    color: #00a2db;
    display: block;
    font-size: .6em;
    margin-bottom: .2em;
    font-weight: 600; 
    letter-spacing: 5px;
  }
}

/*
@media (min-width: 0px)   and (max-width: 426px) {}
@media (min-width: 427px) and (max-width: 768px) {}
@media (min-width: 769px) and (max-width: 1280px) {}
@media (min-width: 1281px) {}
*/
