// Variable layout (Ivan)

// Define defaults for each variable.

// Our variables
$base-font-family-laptop: 'Roboto', Helvetica, Arial, sans-serif;
$base-font-family-palm:   'Arial', Helvetica, sans-serif;
$base-font-size:   1rem;
$base-font-weight: 300;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     1.875rem; //30px

$text-color:       #404040;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    56.95rem; //900px;

$on-palm:          37.5rem; //600px;
$on-laptop:        50rem; //800px;

// Minima also includes a mixin for defining media queries.
// Use media queries like this:
// @include media-query($on-palm) {
//     .layout-wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }

$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$base-font-size:   1rem !default;
$base-font-weight: 400 !default;

$blue-gradient-light: #00a2db;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     3.125rem !default; //50px

$text-color:       #111 !default;
$frontpage-menu-color: #fff;
$background-color: #fdfdfd !default;
$brand-color:      #2a7ae2 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

// Use media queries like this:
// @include media-query($on-palm) {
//     .layout-wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (min-width: $device) {
        @content;
    }
}