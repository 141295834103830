
 
.page-header{
  
  margin-bottom: 1.3rem;

  /*  
  @media (min-width: 0px)   and (max-width: 426px) {}
  */
  @media (min-width: 427px) and (max-width: 768px) {
    margin-bottom: 1.1em;
    margin-top: 0.5em;
  }


  @media (min-width: 769px) and (max-width: 1280px) {
    margin-bottom: 1em;
    margin-top: .7em;
    display: inline-block;
    width: 100%;
  }
  

  @media (min-width: 1281px) {
    margin-top: 1em;
    margin-bottom: 2.2em;
  }  

}



/*
@media (min-width: 0px)   and (max-width: 426px) {}
@media (min-width: 427px) and (max-width: 768px) {}
@media (min-width: 769px) and (max-width: 1280px) {}
@media (min-width: 1281px) {}
*/
