.btn {
    max-width:6rem;
    margin: 0 auto;
    background: #79f55a;
    background-image: -webkit-linear-gradient(top, #79f55a, #00fabc);
    background-image: -moz-linear-gradient(top, #79f55a, #00fabc);
    background-image: -ms-linear-gradient(top, #79f55a, #00fabc);
    background-image: -o-linear-gradient(top, #79f55a, #00fabc);
    background-image: linear-gradient(to bottom, #79f55a, #00fabc);
    -webkit-border-radius: 5;
    -moz-border-radius: 5;
    border-radius: 5px;
    font-family: Arial;
    color: #ffffff;
    font-size: 20px;
    padding: 10px 20px 10px 20px;
    border: solid #e8edf0 6px;
    text-decoration: none;
    text-align: center;
}

.btn:hover {
  background: #99fc3c;
  background-image: -webkit-linear-gradient(top, #99fc3c, #34d989);
  background-image: -moz-linear-gradient(top, #99fc3c, #34d989);
  background-image: -ms-linear-gradient(top, #99fc3c, #34d989);
  background-image: -o-linear-gradient(top, #99fc3c, #34d989);
  background-image: linear-gradient(to bottom, #99fc3c, #34d989);
  text-decoration: none;
}
