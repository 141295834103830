



// Link logo
.logo{ 

    a {
        color: #ffffff;
        display: block;
        padding-bottom: .6rem;
        padding-top: .6rem;
        width: 7rem;

        img{ 
            width: 100%;
        }            
    }

}


// Layout
// =============================================================================

.layout-frontpage {

    .layout-header{

        &::before{
            content: "";
            background-color: #00a2db;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: -1; 
        }

        &::after {
            background-image: url("https://images.unsplash.com/photo-1484542603127-984f4f7d14cb?dpr=1&auto=format&fit=crop&w=1500&h=902&q=80&cs=tinysrgb&crop=");
            background-position: center;
            background-size: cover;
            bottom: 0;
            content: "";
            left: 0;
            opacity: 0.2;
            position: absolute;
            right: 0;
            top: 0;
            z-index: -1;   
        }        
        
        .layout-wrapper{
            
            &:after{
                -ms-transform: rotate(90deg) scale(1,2); /* IE 9 */
                -webkit-transform: rotate(90deg) scale(1,2); /* Chrome, Safari, Opera */
                bottom: -.62rem;
                color: #59d3fe;
                content:"›";
                font-size: 3rem;
                position: fixed;
                transform: rotate(90deg) scale(1,2);                
                z-index: -1;
                
                @include media-query(768px) {
                    font-size: 5rem;
                }                
            }            
            
            .logo {
                
                a {
                    width: 11rem;

                    @include media-query(768px) {
                        width: 22.5rem;    
                    }
                }        
            }
        }    
    }//site.header


    // Manage menù input beaviour in frontpage page
    input[id=show-menu]:checked ~ .layout-header > .layout-wrapper > .logo {
        
        a {
            width: 7rem;
        }
    }
}








// Icons
// =============================================================================

.icon > svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;

    path {
        fill: $grey-color;
    }
}







