
.frontpage-title{
    position: absolute;
    bottom: 2rem;
    left: 0;
    width: 100%;
    text-align: center;
}


.layout-frontpage{ 
    
    .layout-header{
        height: 100%;
        background: none;
        
        .layout-wrapper{           
            -webkit-justify-content: center;
            align-items: center;        
            display:flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            margin: 0;  
            max-width: 100%;
        }
    }
}

.layout-frontpage{
    
    .layout-wrapper{
        padding-bottom: 3rem;
        
        @include media-query(1024px) {
            padding-top: 4rem;
        }
    }
    

    .frontpage-section{
        background: #e5f6fc;
        
        .fps-wrap{
            max-width: $content-width;
            margin: 0 auto;
            overflow: hidden;
            font-size: 1.1rem;
            padding-top: 3rem;
            padding-bottom: 3rem;
            
            @include media-query(640px) {
                font-size: 1.2rem;    
            } 

            @include media-query(768px) {
                font-size: 1.4rem;    
            }

            @include media-query(1280px) {
                font-size: 1.6rem;    
            }            
            
            blockquote{
                border: 0;    
                padding: 0;
                margin: 0;
                padding-right: 10%;
                padding-left: 10%;
                font-size: 1.2rem;
                
                p{
                    font-size: 1rem;
                    color: #00abdf;

                }
                
                
                &:before{
                    content: '"';
                    font-size: 3rem;
                    float: left;
                    position: relative;
                    top: -0.25rem;
                    left: -.25rem;
                    color: #b7e7f6;
                    height: 0;
                }
                
                p{
                    line-height: 1.3;
                    float: right;
                    width: 95.8%;
                }
                
                footer{
                    clear: both;
                    padding-left: 1rem;
                    
                    cite{
                        font-size: .8rem;
                        position: relative;        
                        top: -.1rem;
                    }
                }
            }
        }
    }
}



// Article
// =============================================================================
 
 

.layout-page, .layout-frontpage{
    
    article{
        //font-size: .75rem;        
    }
}

.layout-post{
    
    article{
        font-size: 1rem;
        background: none;
        
        padding: 0;
        box-shadow: none;
        border-radius: 0;
    }


}


.is-flexible{ 
    @media (min-width: 769px){
        flex: auto;
        width: calc(100% - 100%);
    }

}

.is-fixed{ 
    @media (min-width: 769px){
        flex: auto;
        width: 680px; /* OLD IE */
        max-width: 680px;
        margin-left: auto ;
        margin-right: auto ;
    }

}




/*
@media (min-width: 0px)   and (max-width: 426px) {}
@media (min-width: 427px) and (max-width: 768px) {}
@media (min-width: 769px) and (max-width: 1280px) {}
@media (min-width: 1281px) {}
*/


