

.post-header {
  margin-bottom: 1.5em;
  margin-top: 1em;
}

.post-meta {
  font-size: 1em;
  color: #565656;

  @media (min-width: 0px)   and (max-width: 768px) {
    margin-bottom: 1em;
  }

  @media (min-width: 769px) {
    display: inline-block;
  }
  
}
  
.post-categories {
    @media (min-width: 769px) {
      display: inline-block;
    }
}

.post-categories a {
    @media (min-width: 0px)   and (max-width: 768px) {
      border-radius: .5em;
      padding-left: 1em;
      padding-right: 1em;
      padding-top: 1.7em;
      padding-bottom: 1.6em;
      color: #555555;
      font-weight: 600;
      letter-spacing: .05rem;
      font-size: .53rem;
      text-transform: uppercase;
      display: inline-block;
      box-shadow: 0px 0px 8px 0px #d6d6d6;
      background: linear-gradient(180deg, #fafafa, #ececec);
      margin-right: .02rem;
      font-family: "Segoe UI","Segoe UI Symbol","serif","sans-serif", "Roboto";
      font-weight: bold;
    }
  
    @media (min-width: 769px) {
        position: relative;
        color: #ffffff;
        background: linear-gradient(0deg, #545454, #838383);
        padding-left: .5em;
        padding-right: .5em;
        padding-bottom: .2em;
        padding-top: .1em;
        font-weight: 100;
        border-top-right-radius: .6em;
        border-bottom-right-radius: .6em;
        font-size: .8em;
        background-clip: padding-box; /* !importanté */
        border: solid 5px transparent; /* !importanté */
        z-index: 3;
        top: -2px;


        &:before {
            content: '';
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            z-index: -99;
            margin-left: -8px; /* !importanté */
            border-top-left-radius: .2em; /* !importanté */
            border-bottom-left-radius: .2em; /* !importanté */
            background: linear-gradient(to bottom, #6bd8ff, #006488);
            width: 8px; /* IE */
          }
    }
  }

.post-categories:before {
    @media (min-width: 769px) {
      content: '•';   
      color: #565656;
      margin-left: .3em;
      padding-right: .3em;
    }
}

.post-image {
  margin-top: 2em;
}
