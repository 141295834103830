@mixin lengh-and-margin{
    width: 85%;    
    margin-left: auto;
    margin-right: auto;
 }

/*
@media (min-width: 0px)   and (max-width: 426px) {}
@media (min-width: 427px) and (max-width: 768px) {}
@media (min-width: 769px) and (max-width: 1280px) {}
@media (min-width: 1281px) {}
*/




/* -----------------------------
 * TILE WALL
 *------------------------------------------*/

 .tile-wall {
    max-width: 950px;
    width: 100%;
    margin: 0 auto;
}

.tile-category{ position: relative; }

.tile-category__title{
    border-bottom: solid 1px #ddd;
    margin-top: 2em;
    margin-bottom: 1.2em;
}

.archive-category .tile-category__title{
    margin-top: 0;
}

/*
.tile-category__title:before {
    content: '⚫';
    color: #00a2db;
    margin-left: -14px;
    padding-right: .3em;
}
*/

.tile-category__show-all { 
    @media (min-width: 427px) and (max-width: 768px) {
      position: absolute;
      top: -3px;
      right: 0;
    }

    @media (min-width: 769px) and (max-width: 1280px) {
        position: absolute;
        top: 0;
        right: 0;
      }

    @media (min-width: 1281px) {
        position: absolute;
        top: 4px;
        right: 0;
    }

  }

.tile-category__show-all a{
    @media (min-width: 0px)   and (max-width: 426px) {
        color: #737373;
        padding: 1.3em;
        margin-top: 1em;
        display: block;
        border: solid 1px #dedede;
        border-radius: .5em;
        text-align: center;
        font-size: .8em;
        letter-spacing: 3px;
        text-transform: uppercase;
        margin-bottom: 4em;
        box-shadow: 0px 0px 15px 0px #dcdcdc;     
    }

    @media (min-width: 427px) and (max-width: 768px) {
      color: #484442;
      padding: .95em;
      display: block;
      text-align: center;
      background: #e6e6e6;
      font-size: .6em;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 400;            
    }

    @media (min-width: 769px) {
        color: #484442;
        padding: .95em;
        display: block;
        text-align: center;
        background: none;
        font-size: .6em;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: 400;        
    }
     
  }

  .tile-category__show-all a:hover{
      text-decoration: none;
      @media (min-width: 769px) {
        background: #e6e6e6;
      }
  }


/*------------------------------------------
 * TILE WRAPPER
 *------------------------------------------*/

.tile-wrapper { 
    @media (max-width: 426px){
      width: 100%;
      list-style-type: none; 
      margin: 0;
      position: relative;
      display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox;      /* TWEENER - IE 10 */
      display: -webkit-flex;     /* NEW - Chrome */
      display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */        
      align-items: stretch;
      flex-flow: wrap;
    }

    @media (min-width: 427px) and (max-width: 768px) {
        width: auto;
        list-style-type: none; 
        margin: 0;
        position: relative;
        display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;      /* TWEENER - IE 10 */
        display: -webkit-flex;     /* NEW - Chrome */
        display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */        
        align-items: stretch;
        flex-flow: wrap;
      }    

    @media (min-width: 769px) and (max-width: 1280px) {
      width: auto;
      list-style-type: none; 
      margin: 0;
      position: relative;
      display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox;      /* TWEENER - IE 10 */
      display: -webkit-flex;     /* NEW - Chrome */
      display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */        
      align-items: stretch;
      flex-flow: wrap;
      margin-left: -1.5%;
      margin-right: -1.5%;
    }

    @media (min-width: 1281px) {
        width: auto;
        list-style-type: none; 
        margin: 0;
        position: relative;
        display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;      /* TWEENER - IE 10 */
        display: -webkit-flex;     /* NEW - Chrome */
        display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */        
        align-items: stretch;
        flex-flow: wrap;
        margin-left: -1.5%;
        margin-right: -1.5%;
      }
} 





/*------------------------------------------
 * TILE COMPONENT
 *------------------------------------------*/

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


.tile-component{
    position: relative;

    @media (max-width: 426px) {
        -webkit-box-flex: 1 0 100%;      /* OLD - iOS 6-, Safari 3.1-6 */
        -moz-box-flex: 1 0 100%;         /* OLD - Firefox 19- */
        //width: 20%;                    /* For old syntax, otherwise collapses. */
        -webkit-flex: 1 0 100%;          /* Chrome */
        -ms-flex: 1 0 100%;              /* IE 10 */
        flex: 1 0 100%;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */
        margin-bottom: 1em;
    }
    

    @media (min-width: 427px) { animation: fadein 1s; }

    @media (min-width: 1281px) {
        position: relative;
        margin-bottom: 3%;
        margin-left: 1.5%;
        margin-right: 1.5%;
        box-shadow: 0px 0px 12px #eaeaea;
        border-radius: 0.2em;
        overflow: hidden;
    }
}

.tile-component a:hover {
    text-decoration: none;
}

.tile-component._onetwinxrow{
    @media (min-width: 427px) and (max-width: 768px) {
        -webkit-box-flex: 1 0 100%;
        -moz-box-flex: 1 0 100%;
        -webkit-flex: 1 0 100%;
        -ms-flex: 1 0 100%;
        flex: 0 0 100%;
        margin-bottom: 1em;
        background: #fff;
    }
}




.tile-component._threetwinsxrow{
    @media (min-width: 769px) and (max-width: 1280px) {
        -webkit-box-flex: 1 0 100%;
        -moz-box-flex: 1 0 100%;
        -webkit-flex: 1 0 100%;
        -ms-flex: 1 0 100%;
        flex: 0 0 30.3333333333%;
        margin-right: 1.5%;
        margin-left: 1.5%;
        margin-bottom: 3%;
    }
}




.tile-component._giant{
    @media (min-width: 1281px) {
      -webkit-box-flex: 0 0 97%;
      -moz-box-flex: 0 0 97%;
      -webkit-flex: 0 0 97%;
      -ms-flex: 0 0 97%;
      flex: 0 0 97%;
    }
}

.tile-component._big{
    @media (min-width: 1281px) {
      -webkit-box-flex: 0 0 51%;
      -moz-box-flex: 0 0 51%;
      -webkit-flex: 0 0 51%;
      -ms-flex: 0 0 51%;
      flex: 0 0 51%;
    }
}

.tile-component._small{
    @media (min-width: 1281px) {
        -webkit-box-flex: 0 0 43%;
        -moz-box-flex: 0 0 43%;
        -webkit-flex: 0 0 43%;
        -ms-flex: 0 0 43%;
        flex: 0 0 43%;
    }
}


.tile-component._xsmall{
    @media (min-width: 1281px) {
        -webkit-box-flex: 0 0 30.3333333333%;
        -moz-box-flex: 0 0 30.3333333333%;
        -webkit-flex: 0 0 30.3333333333%;
        -ms-flex: 0 0 30.3333333333%;
        flex: 0 0 30.3333333333%;
        box-shadow: none;
        border-bottom: 0;
        border-radius: 0;
        overflow: unset;
    }
}


.layout-sidebar .tile-component.in-sidebar{
    @media (min-width: 769px) {
        -webkit-box-flex: 0 0 100%;
        -moz-box-flex: 0 0 100%;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        box-shadow: none;
        border-bottom: 0;
        border-radius: 0;
        overflow: unset;
    }
    
}






/*------------------------------------------
 * TILE COMPONENT LINK
 *------------------------------------------*/

.tile-component-link{ 
    @media (max-width: 426px) {
        color: rgb(0, 0, 0); 
        height: 100%;
        margin: 0;
        padding: 0;
        display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;      /* TWEENER - IE 10 */
        display: -webkit-flex;     /* NEW - Chrome */
        display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */     
        flex-direction: column;
        border-radius: 0.7em;
        box-shadow: 0px 15px 15px 0px #dcdcdc;
        overflow: hidden;
    }
    

    /* Safari 9 */
    /* Source: https://stackoverflow.com/questions/16348489/is-there-a-css-hack-for-safari-only-not-chrome */
    @supports (overflow:-webkit-marquee) and (justify-content:inherit) {
        display: block ;
    }

    &:hover{
        //background: yellow;
        text-decoration: none;
    }

}//.tile-component-link




._onetwinxrow .tile-component-link{
    @media (min-width: 427px) and (max-width: 768px) {
        display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;      /* TWEENER - IE 10 */
        display: -webkit-flex;     /* NEW - Chrome */
        display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
        color: rgb(0, 0, 0); 
        height: 100%;
        margin: 0;
        padding: 0;
        /* box-shadow: 0px 15px 15px 0px #dcdcdc; */
        overflow: hidden;
        flex-direction: row;

    }
}



._threetwinsxrow .tile-component-link{
    @media (min-width: 769px) and (max-width: 1280px) {
        display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;      /* TWEENER - IE 10 */
        display: -webkit-flex;     /* NEW - Chrome */
        display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
        color: rgb(0, 0, 0); 
        flex-direction: column;
        height: 100%;
        margin: 0;
        
        /* box-shadow: 0px 15px 15px 0px #dcdcdc; */
        overflow: hidden;     
    }
}


._giant .tile-component-link{
    @media (min-width: 1281px) {
        display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;      /* TWEENER - IE 10 */
        display: -webkit-flex;     /* NEW - Chrome */
        display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
        color: rgb(0, 0, 0);         
        flex-direction: row;   
        height: auto;     
    }

}

._big .tile-component-link{
    @media (min-width: 1281px) {
        display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;      /* TWEENER - IE 10 */
        display: -webkit-flex;     /* NEW - Chrome */
        display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
        color: rgb(0, 0, 0);         
        flex-direction: column;   
        height: 100%;     
    }
}


._small .tile-component-link{
    @media (min-width: 1281px) {
        display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;      /* TWEENER - IE 10 */
        display: -webkit-flex;     /* NEW - Chrome */
        display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
        color: rgb(0, 0, 0);         
        flex-direction: column;   
        height: 100%;     
    }

}


._xsmall .tile-component-link{
    @media (min-width: 1281px) {
        display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;      /* TWEENER - IE 10 */
        display: -webkit-flex;     /* NEW - Chrome */
        display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
        color: rgb(0, 0, 0);         
        flex-direction: column;   
        height: 100%;     
    }

}



/*------------------------------------------
 * TILE MEDIA
 *------------------------------------------*/

.tile-media{
    @media (max-width: 426px) {
        position: relative;             // necessario per Aspect Ratio
        //width: 100%;                  // necessario per Aspect Ratio
        padding-top: 56.25%;            // emula Aspect Ratio 16:9
        overflow: hidden;
        flex: 1 0 0;
    }
}


.tile-media .image{
    @media (max-width: 426px) {
      position: absolute;         // necessario per Aspect Ratio
      top: 0;                     // necessario per Aspect Ratio
      left: 0;                    // necessario per Aspect Ratio
      //width: 480px;
      //height: 388px;
      width: 100%;                 // necessario per Aspect Ratio
      height: 100%;                // necessario per Aspect Ratio
      background-size: cover;      // necessario per Aspect Ratio
      background-position: center; // necessario per Aspect Ratio
    }


    @media (min-width: 1280px) {
      -webkit-transition: transform 0.36s ease-in-out;
         -moz-transition: transform 0.36s ease-in-out;
          -ms-transition: transform 0.36s ease-in-out;
                overflow: hidden;         
    }
}


.tile-media .image:hover{
    @media (min-width: 1280px) {
         -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1);
           -o-transform: scale(1.1);
          -ms-transform: scale(1.1);
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
              
    }
}

.tile-media .image:hover::after{
    background:#00a2db;
    
    
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    opacity: 0.2;
}




._onetwinxrow .tile-media{
    @media (min-width: 427px) and (max-width: 768px) {
      position: relative;
      overflow: hidden;
      padding-top: 28%;
      -webkit-box-flex: 0 0 42%;
      -moz-box-flex: 0 0 42%;
      -webkit-flex: 0 0 42%;
      -ms-flex: 0 0 42%;
      flex: 0 0 42%;
    }

    @media (min-width: 427px) and (max-width: 769px) {
        .image{
            position: absolute;         // necessario per Aspect Ratio
            top: 0;                     // necessario per Aspect Ratio
            left: 0;                    // necessario per Aspect Ratio
            //width: 480px;
            //height: 388px;
            width: 100%;                 // necessario per Aspect Ratio
            height: 100%;                // necessario per Aspect Ratio
            background-size: cover;      // necessario per Aspect Ratio
            background-position: center; // necessario per Aspect Ratio
        }
    }

}



._threetwinsxrow .tile-media{
    @media (min-width: 769px) and (max-width: 1280px) {
      position: relative;
      overflow: hidden;
      padding-top: 55%;
      -webkit-box-flex: 0 0 0%;
      -moz-box-flex: 0 0 0%;
      -webkit-flex: 0 0 0%;
      -ms-flex: 0 0 0%;
      flex: 0 0 0%;
      margin-bottom: 5%;
    }

    @media (min-width: 769px) and (max-width: 1280px) {
        .image{
            position: absolute;         // necessario per Aspect Ratio
            top: 0;                     // necessario per Aspect Ratio
            left: 0;                    // necessario per Aspect Ratio
            //width: 480px;
            //height: 388px;
            width: 100%;                 // necessario per Aspect Ratio
            height: 100%;                // necessario per Aspect Ratio
            background-size: cover;      // necessario per Aspect Ratio
            background-position: center; // necessario per Aspect Ratio
        }
    }    
}



._giant .tile-media{
    @media (min-width: 1281px) {
      padding-top: 30%;  
      -webkit-box-flex: 0 0 62%;
      -moz-box-flex: 0 0 62%;
      -webkit-flex: 0 0 62%;
      -ms-flex: 0 0 62%;
      flex: 0 0 62%;
      position: relative;
    }

    @media (min-width: 1281px) {
        .image{
            position: absolute;         // necessario per Aspect Ratio
            top: 0;                     // necessario per Aspect Ratio
            left: 0;                    // necessario per Aspect Ratio
            //width: 480px;
            //height: 388px;
            width: 100%;                 // necessario per Aspect Ratio
            height: 100%;                // necessario per Aspect Ratio
            background-size: cover;      // necessario per Aspect Ratio
            background-position: center; // necessario per Aspect Ratio
        }
    }
}



._big .tile-media{
    @media (min-width: 1281px) {
      padding-top: 56%;  
      -webkit-box-flex: 0 0 0;
      -moz-box-flex: 0 0 0;
      -webkit-flex: 0 0 0;
      -ms-flex: 0 0 0;
      flex: 0 0 0;
      position: relative;
    }

    @media (min-width: 1281px) {
        .image{
            position: absolute;         // necessario per Aspect Ratio
            top: 0;                     // necessario per Aspect Ratio
            left: 0;                    // necessario per Aspect Ratio
            //width: 480px;
            //height: 388px;
            width: 100%;                 // necessario per Aspect Ratio
            height: 100%;                // necessario per Aspect Ratio
            background-size: cover;      // necessario per Aspect Ratio
            background-position: center; // necessario per Aspect Ratio
        }
    }
}



._small .tile-media{
    @media (min-width: 1281px) {
      padding-top: 66.1%;
      -webkit-box-flex: 0 0 0;
      -moz-box-flex: 0 0 0;
      -webkit-flex: 0 0 0;
      -ms-flex: 0 0 0;
      flex: 0 0 0;
      position: relative;

    }

    @media (min-width: 1281px) {
        .image{
            position: absolute;         // necessario per Aspect Ratio
            top: 0;                     // necessario per Aspect Ratio
            left: 0;                    // necessario per Aspect Ratio
            //width: 480px;
            //height: 388px;
            width: 100%;                 // necessario per Aspect Ratio
            height: 100%;                // necessario per Aspect Ratio
            background-size: cover;      // necessario per Aspect Ratio
            background-position: center; // necessario per Aspect Ratio
        }
    }
}


._xsmall .tile-media{
    @media (min-width: 1281px) {
      padding-top: 66.1%;
      -webkit-box-flex: 0 0 0;
      -moz-box-flex: 0 0 0;
      -webkit-flex: 0 0 0;
      -ms-flex: 0 0 0;
      flex: 0 0 0;
      position: relative;
      box-shadow: 0px 0px 2px 0px rgb(135, 135, 135);
      overflow: unset;
      border-radius: 0.3em;
      overflow: hidden;

    }

    @media (min-width: 1281px) {
        .image{
            position: absolute;         // necessario per Aspect Ratio
            top: 0;                     // necessario per Aspect Ratio
            left: 0;                    // necessario per Aspect Ratio
            //width: 480px;
            //height: 388px;
            width: 100%;                 // necessario per Aspect Ratio
            height: 100%;                // necessario per Aspect Ratio
            background-size: cover;      // necessario per Aspect Ratio
            background-position: center; // necessario per Aspect Ratio
            border-radius: 0.3em;
        }
    }
}



/*------------------------------------------
 * TILE CONTENT
 *------------------------------------------*/

 .tile-content{

    @media (max-width: 426px) {
        width: 100%;                   
        margin-top: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        position: relative;
        overflow-x: hidden;
        display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;      /* TWEENER - IE 10 */
        display: -webkit-flex;     /* NEW - Chrome */
        display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    @media (min-width: 1281px){
        background: #fff;
        z-index: 3;
    }


} //.tile-content


._onetwinxrow .tile-content{
    @media (min-width: 427px) and (max-width: 768px) {
        padding-top: 3%;
        padding-bottom: 3%;
        padding-left: 5%;
        padding-right: 5%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
}


._threetwinsxrow .tile-content{
    @media (min-width: 769px) and (max-width: 1280px) {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
}


._giant .tile-content{
    @media (min-width: 1281px) {
        padding: 3.5%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100% /* IE */;
    }
}

._big .tile-content{
    @media (min-width: 1281px) {
        padding-top: 1.2em;
        padding-bottom: 1.2em;
        padding-left: 4%;
        padding-right: 4%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
}




._small .tile-content{
    @media (min-width: 1281px) {
        padding-top: 1.2em;
        padding-bottom: 1.2em;
        padding-left: 6%;
        padding-right: 6%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
}



._xsmall .tile-content{
    @media (min-width: 1281px) {
        padding-top: .7em;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        background: none;
    }
}



/*------------------------------------------
 * TILE HEADER
 *------------------------------------------*/

.tile-header{
   /* width: 100%;*/

}






/*------------------------------------------
 * TILE TITLE
 *------------------------------------------*/

.tile-title{
    @media (max-width: 426px) {
        @include lengh-and-margin;
        margin-top: 1em;
    }

}



.tile-title h2{
    @media (max-width: 426px) {
      display: block;
      font-size: 1.2em;
      font-weight: bold;
      margin: 0;
      line-height: 1.1;
      margin-bottom: 0.5em;
      
    }

    @media (min-width: 427px) and (max-width: 768px) {
      display: block;
      font-size: 1.14em;
      font-weight: 700;
      margin: 0;
      line-height: 1.1;
      margin-bottom: 0.5em;
    }

    @media (min-width: 769px) and (max-width: 1280px) {
        font-size: 1.1em;
        font-weight: 700;
        margin: 0;
        line-height: 1.1;
        margin-bottom: 0.4em;
      }
    
    @media (min-width: 1281px) { 
        font-weight: 700;
        font-size: 1.2em;
        line-height: 1.2;
        margin-top: 0;
    }
}



._giant .tile-title h2{
    @media (min-width: 1281px) { 
        font-size: 1.5em;
    }
}

._xsmall .tile-title h2{
    @media (min-width: 1281px) { 
        font-size: 1.1em;
        margin-bottom: .2em;
    }
}



/*------------------------------------------
 * TILE SUMMARY
 *------------------------------------------*/
 .tile-summary{}
 
 .post-summary{
    @media (max-width: 426px) {
        line-height: 1.3;
        font-weight: 300;    
        font-size: 1em;
        overflow: hidden;
        margin-bottom: 0.5em;
    }

} // .post-summary  



._onetwinxrow .post-summary{
  @media (min-width: 427px) and (max-width: 768px) {
    font-size: .9em;
  }
}


._threetwinsxrow .post-summary{
    @media (min-width: 769px) and (max-width: 1280px) {
      font-size: 1em;
    }
  }


.tile-summary, .tile-entry{
    @media (max-width: 426px) {
        @include lengh-and-margin;
    }

}



.tile-summary{

    p{
        @media (min-width: 1281px) { 
         font-size: 1em;
        }
    }

}



._big .tile-summary,
._small .tile-summary{

    p{
        @media (min-width: 1281px) { 
         display: none;
        }
    }

}





/*------------------------------------------
 * TILE ENTRY
 *------------------------------------------*/

.tile-entry {
    font-size: 0.8em; 
    padding-bottom: 1.5em; 

    @media (min-width: 427px) {
      font-size: 0.6em;
      padding-bottom: 1.5em;
      letter-spacing: .12em;
      font-weight: bold;
      color: #555555;

      b{ font-weight: normal; }
    }

    @media (min-width: 1281px) { padding: 0; }
}


._onetwinxrow .tile-entry{
    @media (min-width: 427px) and (max-width: 768px) {
        padding: 0;
    }
}

._threetwinsxrow .tile-entry{
    @media (min-width: 769px) and (max-width: 1280px) {
        padding: 0;
    }
}



/* DA CANCELLARE */
.read-more{
    
    &:before{
        content: "+";
        right: .3rem;
        position: absolute;
        //z-index: 9999;
        bottom: -.3rem;
        right: .2rem;
        color: #3aad73;
    } // &:before
    
    a{
        width: 0;
        height: 0;
        //overflow: hidden; 
        text-indent: -99999px;
        border-style: solid;
        border-width: 0 0 1.5rem 1.5rem;
        border-color: transparent transparent rgba(119, 119, 119, 0.13) transparent;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
        
        @include media-query($on-laptop) {
            &.tooltip{
                
                &:hover{
                    
                    &:after{
                        font-size: .8rem;
                        background: #333;
                        background: rgba(0,0,0,.8);
                        border-radius: 5px;
                        color: #fff;
                        content: attr(title);
                        text-indent: 0;
                        bottom: -.85rem;
                        line-height: 1;
                        right: 0;
                        padding: 5px 15px;
                        position: absolute;
                        z-index: 2;
                        width: 7rem;
                        text-align: center;
                        display: block;
                        padding-top: .5rem;
                        padding-bottom: .5rem;
                    } // &:after
                    
                    &:before{
                        border: solid;
                        border-color: #333 transparent;
                        border-width: 6px 6px 0 6px;
                        bottom: -1rem;
                        right: .28rem;
                        content: "";
                        position: absolute;
                        z-index: 2;
                    } //&:before
                } // &:hover
            } // &.tooltip
        }
    } // a
} // .read-more